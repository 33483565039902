// ! Appel JS permettant de recupérer l'historique des commandes
// ! et de les afficher dans la page "historique"
// ! (fonctionne avec le fichier ClientUserCommandesHistoriques.php)
console.log("question.js chargé");
const container = document.getElementById("historiqueContainer");
const getCommandesHistoriques = async () => {
	const resp = await fetch(Routing.generate("customer_historique_list"), {
		method: "GET",
	});
	const commandes = await resp.json(); // Convertir la réponse en JSON
	// Si il n'y a pas de commandes, ne faites rien
	if (commandes.length === 0) {
		return;
	}

	// Créez le sélecteur
	const select = document.createElement("select");
	select.id = "commandeSelect";
	select.name = "commandeSelect";
	select.classList.add("form-control");

	// Ajoutez une option vide au début
	const defaultOption = document.createElement("option");
	defaultOption.value = "";
	defaultOption.textContent = "Choisissez une commande";
	select.appendChild(defaultOption);

	// Itérer sur les commandes et ajouter une nouvelle option pour chaque commande
	commandes.forEach((commande) => {
		const option = document.createElement("option");
		option.value = commande.reference;
		option.textContent = `${commande.reference} - ${new Date(commande.created_at).toISOString().split("T")[0]} - ${commande.total_ttc}€`;
		select.appendChild(option);
	});
	// Créez le label
	const label = document.createElement("label");
	label.htmlFor = "commandeSelect";
	label.textContent = "Historique des commandes";

	// Ajoutez le label et le sélecteur au container
	
	container.appendChild(label);
	container.appendChild(select);
};

const contactModal = document.getElementById("contactModal");
// attendre le chargement de la page 

// Event bottstrap on show and hide modal
contactModal.addEventListener("show.bs.modal", (e) => {
  console.log(e);
  getCommandesHistoriques();
});

contactModal.addEventListener("hidden.bs.modal", (e) => {
  container.innerHTML = "";
});